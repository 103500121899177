import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';

import { stringifyQuery } from '../lib/helpers/url';
import { Url } from '../types';

export interface RouterTransitionOptions {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
}

export type UrlGetter = (query?: string | number | Record<string, any>) => string;

interface RouteEntry {
  url: UrlGetter;
  to: (query?: Record<string, any> | string, as?: Url, options?: RouterTransitionOptions) => void;
}

interface CreateRouteOptions<Page extends string> {
  section: string;
  getUrl: UrlGetter;
  page?: Record<Page, UrlGetter>;
  replace?: boolean;
}

export interface BaseRouteItemProps extends RouteEntry {
  section: string;
}

export type RouteItem<Page extends string = any> = Page extends string
  ? BaseRouteItemProps & { page: Record<Page, RouteEntry> }
  : BaseRouteItemProps;

export const createRouteUrl = (url: string, query?: Record<string, any>) =>
  query ? `${url}?${stringifyQuery(query)}` : `${url}`;

const useSectionRoute = <Page extends string = ''>(options: CreateRouteOptions<Page>) => {
  const { section, getUrl, page, replace: isReplace } = options;

  const { push, replace } = useRouter();
  const redirectFn = isReplace ? replace : push;

  const redirect = useCallback(
    (fn: UrlGetter) => (query?: Record<string, any>, as?: Url, options?: RouterTransitionOptions) => {
      const url = fn(query);
      redirectFn(url, as, options);
    },
    [redirectFn]
  );

  return useMemo(() => {
    const root = { url: getUrl, to: redirect(getUrl) };

    if (!page) {
      return { section, ...root } as BaseRouteItemProps;
    }

    const pagesMap = Object.entries(page).reduce((acc, [key, value]) => {
      acc[key] = { url: value, to: redirect(value as UrlGetter) };
      return acc;
    }, {} as Record<Page, RouteEntry>);
    return { section, ...root, page: pagesMap } as RouteItem<Page>;
  }, [section, getUrl, page, redirect]);
};

export default useSectionRoute;
