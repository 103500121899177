/* eslint-disable react/forbid-prop-types */
import React, { memo, useContext, useRef, useState } from 'react';
import classnames from 'classnames';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import PropTypes from 'prop-types';

import { PagePropsContext } from '../../../../contexts';
import { declOfNum } from '../../../../helpers/tools';
import useMutationObserver from '../../../../hooks/useMutationObserver';
import { useAppUserFavorites } from '../../../../src/core';
import Logo from '../../../Logo';
import RegionConfirm from '../../../RegionConfirm';
import Typography from '../../../Typography';
import HeaderDropdownAction from '../HeaderDropdownAction';

import styles from './HeaderNav.module.css';

import arrowInlineRightIcon from '/icons/ArrowInlineRightIcon';
import arrowMiniDownIcon from '/icons/ArrowMiniDownIcon';
import basketIcon from '/icons/BasketIcon';
import favoriteIcon from '/icons/FavoriteIcon';
import loginIcon from '/icons/LoginIcon';
import menuIcon from '/icons/MenuIcon';
import phoneIcon from '/icons/PhoneIcon';
import pointIcon from '/icons/PointIcon';
import profileIcon from '/icons/ProfileIcon';

const ProfileDropdown = dynamic(() => import('../ProfileDropdown'), { ssr: false });

const HeaderNav = (props) => {
  const {
    showModal,
    shopsCount,
    currentShopsCount,
    user,
    cartCount,
    logout,
    isDrawerOpen,
    isRegionConfirmationShowed,
    onToggleDrawer,
    onToggleDropdown,
    onCloseDropdown,
  } = props;

  // TODO: Убрать после рефакторинга лэйаута и выпиливания redux
  const { list: localFavorites } = useAppUserFavorites();
  const favoritesCount = localFavorites?.length ?? 0;

  const { region } = useContext(PagePropsContext);
  const isAuthorized = !!(user?.authorized);

  const phoneRef = useRef();
  const [openedDropdown, setOpenedDropdown] = useState();
  const [phoneHref, setPhoneHref] = useState('tel:+78003332135');

  useMutationObserver(
    phoneRef,
    (mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'href') {
          // console.log('MutationObserver', mutation, phoneRef.current?.href);
          setPhoneHref(phoneRef.current?.href);
        }
      });
    },
    { attributes: true }
  );

  const makeHandleToggleDropdown =
    (key) =>
    ({ isOpen }) => {
      if (isOpen) {
        setOpenedDropdown(key);
      } else if (key === openedDropdown) {
        setOpenedDropdown(null);

        if (onCloseDropdown) {
          onCloseDropdown();
        }
      }

      if ((key === 'search' || key === 'basket') && !openedDropdown && isDrawerOpen) {
        onToggleDrawer();
      }

      if (openedDropdown === 'search' || key === 'search') {
        if (onToggleDropdown) {
          onToggleDropdown({ isOpen });
        }
      }
    };

  // const makeHandleCloseDropdown = (key) => () => {
  //   if (key === openedDropdown) {
  //     setOpenedDropdown(null);
  //   }

  //   if (onCloseDropdown) {
  //     onCloseDropdown();
  //   }
  // };

  const trackLogoClick = () => {
    window.dataLayer?.push({
      event: 'autoEvent',
      eventCategory: 'Верхнее меню',
      eventAction: 'Логотип',
      eventLabel: '',
    });
  };

  const trackPhoneClick = (e) => {
    window.dataLayer?.push({
      event: 'autoEvent',
      eventCategory: 'Верхнее меню',
      eventAction: 'Ссылка - телефон',
      eventLabel: e.target.innerText,
    });
  };

  const trackShopsClick = () => {
    window.dataLayer?.push({
      event: 'autoEvent',
      eventCategory: 'Верхнее меню',
      eventAction: 'Адреса магазинов',
      eventLabel: `${currentShopsCount} ${declOfNum(shopsCount, ['Магазин', 'Магазина', 'Магазинов'])}`,
    });
  };

  const trackRegionsClick = () => {
    window.dataLayer?.push({
      event: 'autoEvent',
      eventCategory: 'Верхнее меню',
      eventAction: 'Выбор региона',
      eventLabel: region?.name,
    });
  };

  const handleRegionSelectClick = () => {
    showModal('RegionSelect');

    trackRegionsClick();
  };

  const handleShopsClick = () => {
    trackShopsClick();
  };

  const handleToggleDrawer = () => {
    if (onToggleDrawer) {
      onToggleDrawer();
    }
  };

  return (
    <nav
      className={classnames(styles.headerNav, 'header-nav')}
      itemScope
      itemType='https://schema.org/SiteNavigationElement'
    >
      <div className={styles.regionConfirmTablet}>
        <RegionConfirm isRegionConfirmationShowed={isRegionConfirmationShowed} />
      </div>
      <div className={classnames(styles.headerNavContent)}>
        <div className={classnames(styles.headerNavGroup)}>
          <div className={styles.regionConfirmNotTablet}>
            <RegionConfirm isRegionConfirmationShowed={isRegionConfirmationShowed} />
          </div>
          <button
            type='button'
            className={classnames(styles.headerNavMenuButton, styles.left, styles.notDesktopLarge)}
            onClick={handleToggleDrawer}
            aria-label='Переключить меню'
          >
            {menuIcon}
          </button>

          <Link
            href='/'
            aria-label='На главную'
            prefetch={false}
            className={classnames(styles.headerNavLogo, styles.headerNavLogoTablet, styles.notTablet)}
            onClick={trackLogoClick}
            itemProp='url'
          >
            <Logo />
          </Link>

          <Typography
            className={classnames(styles.headerNavLogoText, styles.desktopLarge)}
            variant='body2'
            component='span'
            color='primary'
            semibold
          >
            Мягкий шаг к счастью
          </Typography>

          <Link prefetch={false} aria-label='Адреса магазинов' href='/adresa-magazinov' passHref legacyBehavior>
            <Typography
              variant='body3'
              component='a'
              bold
              className={classnames(styles.headerNavShopLink)}
              onClick={handleShopsClick}
              itemProp='url'
            >
              <div className={styles.headerNavLinkIcon}>{pointIcon}</div>
              {currentShopsCount}
              &nbsp;
              {declOfNum(currentShopsCount, ['Магазин', 'Магазина', 'Магазинов'])}
            </Typography>
          </Link>

          <Typography
            variant='body3'
            component='button'
            className={classnames(styles.headerNavRegionLink)}
            onClick={handleRegionSelectClick}
            type='button'
          >
            {region?.name}
            &nbsp;
            {arrowMiniDownIcon}
          </Typography>
        </div>

        <div className={classnames(styles.headerNavGroup, styles.notTablet)}>
          <Typography
            variant='body2'
            component='a'
            bold
            color='primary'
            className={classnames(styles.headerNavPhone, 'call_phone_495')}
            href='tel:+78003332135'
            onClick={trackPhoneClick}
            itemProp='url'
            ref={phoneRef}
          >
            8 800 333-21-35
          </Typography>
        </div>

        <div className={classnames(styles.headerNavGroup, styles.desktopLarge)}>
          <Link prefetch={false} aria-label='Виртуальная примерка' href='/vr' passHref legacyBehavior>
            <Typography
              variant='body2'
              color='secondary'
              component='a'
              className={styles.headerNavRegularLink}
              itemProp='url'
            >
              Виртуальная примерка ковра
              {arrowInlineRightIcon}
            </Typography>
          </Link>
          <Link prefetch={false} aria-label='Демонстрация' href='/demonstration' passHref legacyBehavior>
            <Typography
              variant='body2'
              color='secondary'
              component='a'
              className={styles.headerNavRegularLink}
              itemProp='url'
            >
              Демонстрация у вас дома
              {arrowInlineRightIcon}
            </Typography>
          </Link>
          <Typography
            href='https://api.whatsapp.com/send/?phone=79777666416&text&app_absent=0'
            variant='body2'
            color='secondary'
            component='a'
            className={styles.headerNavRegularLink}
            itemProp='url'
          >
            Видеоконсультация
            {arrowInlineRightIcon}
          </Typography>
        </div>

        <div className={styles.headerNavGroup}>
          <div className={styles.headerNavLogoTabletWrapper}>
            <Link
              aria-label='На главную'
              href='/'
              prefetch={false}
              className={classnames(styles.headerNavLogo, styles.tablet)}
              onClick={trackLogoClick}
              itemProp='url'
            >
              <Logo />
            </Link>
          </div>
        </div>

        <div className={styles.headerNavGroup}>
          <HeaderDropdownAction
            className={classnames(styles.headerNavPointButton, styles.tablet)}
            href='/adresa-magazinov'
            theme='icon'
            icon={pointIcon}
            label='Адреса магазинов'
            prefetch={false}
          />

          <HeaderDropdownAction
            className={classnames(styles.headerNavPhoneButton, styles.notDesktopLarge)}
            href={phoneHref}
            theme='icon'
            icon={phoneIcon}
            label='Контакты'
          />

          <HeaderDropdownAction
            className={classnames(styles.headerNavFavButton, styles.notTablet)}
            theme='icon'
            href='/favorites'
            badge={favoritesCount}
            icon={favoriteIcon}
            title='Избранное'
            prefetch={false}
            label='Избранное'
          />

          <HeaderDropdownAction
            className={classnames(styles.headerNavBasketButton, styles.notTablet)}
            theme='icon'
            href='/cart'
            badge={cartCount}
            icon={basketIcon}
            title='Корзина'
            prefetch={false}
            label='Корзина'
          />

          {isAuthorized ? (
            <HeaderDropdownAction
              className={classnames(styles.headerNavProfileButton, styles.desktopLarge)}
              theme='icon'
              icon={profileIcon}
              title='Профиль'
              popupProps={{
                popupWidth: 188,
                popupOffsetRight: 0,
              }}
              isOpen={openedDropdown === 'profile'}
              onToggleDropdown={makeHandleToggleDropdown('profile')}
              dropdown={<ProfileDropdown logout={logout} />}
            />
          ) : (
            <HeaderDropdownAction
              className={classnames(styles.headerNavCabinetButton, styles.desktopLarge)}
              theme='icon'
              href='/cabinet'
              icon={loginIcon}
              title='Вход'
              label='Вход'
              prefetch={false}
            />
          )}
        </div>
      </div>
    </nav>
  );
};

HeaderNav.propTypes = {
  shopsCount: PropTypes.number,
  currentShopsCount: PropTypes.number,
  user: PropTypes.object,
  region: PropTypes.object,
  showModal: PropTypes.func,
  logout: PropTypes.func,
  favorites: PropTypes.array,
  cart: PropTypes.object,
  isSearchFetching: PropTypes.bool,
  onToggleDrawer: PropTypes.func,
  onToggleDropdown: PropTypes.func,
  onCloseDropdown: PropTypes.func,
};

HeaderNav.defaultProps = {
  shopsCount: null,
  currentShopsCount: null,
  user: null,
  region: null,
  showModal: null,
  logout: null,
  favorites: null,
  cart: null,
  isSearchFetching: null,
  onToggleDrawer: null,
  onToggleDropdown: null,
  onCloseDropdown: null,
};

export default memo(HeaderNav);
