import { useEffect, useMemo, useState } from 'react';
import throttle from 'lodash/throttle';

const useWindowResize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSizeInner] = useState({
    width: global?.window?.innerWidth || undefined,
    height: global?.window?.innerHeight || undefined,
  });

  const setWindowSize = useMemo(() => throttle(x => setWindowSizeInner(x), 50), []);

  const handleResize = () => {
    // Set window width/height to state
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    // Handler to call on window resize
    if (!process.browser) {
      return undefined;
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
};

export default useWindowResize;
