import Nuka from 'nuka-carousel';
import classnames from 'classnames';
import throttle from 'lodash/throttle';

import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';

import SvgIcon from '../SvgIcon';

import arrowLeft from '../../assets/imgs/common/arrow-left.svg';
import arrowRight from '../../assets/imgs/common/arrow-right.svg';
import arrowLeftWithBorder from '../../assets/imgs/common/arrow-left-with-border.svg';
import arrowRightWithBorder from '../../assets/imgs/common/arrow-right-with-border.svg';

import styles from './Carousel.module.css';


function renderDefaultBottomRightControls(options) {
  const { slideCount, currentSlide, goToSlide } = options;
  const points = new Array(slideCount || 0).fill(true);

  return (
    <div className={styles.points}>
      {points.map((point, i) => (
        <button
          key={`point-${i}`}
          type='button'
          onClick={() => goToSlide(i)}
          className={classnames(styles.point, { [styles.selected]: currentSlide === i })}
        />
      ))}
    </div>
  );
}

const Carousel = (props) => {
  const {
    goToSlideRef,
    className,
    containerClassName,
    children,
    isSingleSlide,
    isAutoplayDisabled,
    isRoundSlideCountDisabled,
    isArrowButtonShadowEnabled,
    isDefaultBottomRightControlsEnabled,
    slideSize,
    slideWidth,
    afterSlide,
    beforeSlide,
    theme,
    renderBottomCenterControls = null,
    renderBottomRightControls = null,
    onCalculationSlideSize = null,
  } = props;

  const [isFewSlidesCount, setIsFewSlidesCount] = useState(false);
  const [slidesToShow, setSlidesToShow] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);

  const otherParams = {};
  const containerRef = useRef();
  const recalcMeasures = useMemo(() => throttle(() => {
    if (containerRef.current) {
      const { width } = containerRef.current?.getBoundingClientRect();

      if (width) {
        let newSlideWidth;

        if (onCalculationSlideSize) {
          const calculatedSlideSize = onCalculationSlideSize();

          newSlideWidth = calculatedSlideSize?.width;
        } else {
          newSlideWidth = (typeof slideSize?.width === 'number' ? slideSize?.width : null) || slideWidth || 320;
        }

        let newSlidesToShow = width < 475 && !isRoundSlideCountDisabled ? 1 : Math.floor((width - (width < 800 ? 32 : 64)) / newSlideWidth);

        // console.log(width, slideWidth, newSlideWidth, (width - (width < 800 ? 32 : 64)) / newSlideWidth)
        if (newSlidesToShow < 1) {
          newSlidesToShow = 1;
        }

        if (isSingleSlide) {
          newSlideWidth = width;
          newSlidesToShow = 1;
        }

        const newIsFewSlidesCount = children?.length ? children?.length * newSlideWidth < width : false;

        setIsFewSlidesCount(newIsFewSlidesCount);
        setSlidesToShow(newSlidesToShow);
      }
    }
  }, 50), [slideWidth, slideSize, children]);

  useEffect(() => {
    recalcMeasures();
  }, []);

  useEffect(() => {
    global?.window?.addEventListener('resize', recalcMeasures);

    return () => {
      global?.window?.removeEventListener('resize', recalcMeasures);
      recalcMeasures.cancel();
    };
  }, [recalcMeasures]);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const handleAfterSlide = (i) => {
    if (afterSlide) {
      afterSlide(i);
    }
  };

  const handleBeforeSlide = (i) => {
    if (beforeSlide) {
      beforeSlide(i);
    }
  };

  if (isFewSlidesCount && !isSingleSlide) {
    return (
      <div ref={containerRef} className={classnames(styles.container, containerClassName, { [styles.loaded]: isLoaded })}>
        <div className={styles.simpleGallery}>
          {children}
        </div>
      </div>
    );
  }

  return (
    <div ref={containerRef} className={classnames(styles.container, containerClassName, { [styles.loaded]: isLoaded })}>
      <Nuka
        className={classnames(styles.customCarousel, className, { [styles.paddedBottom]: isDefaultBottomRightControlsEnabled })}
        slidesToShow={slidesToShow || 1}
        wrapAround
        autoplay={process.env.NODE_ENV === 'production' && !isAutoplayDisabled}
        autoplayInterval={8000}
        afterSlide={handleAfterSlide}
        beforeSlide={handleBeforeSlide}
        width='100%'
        heightMode='max'
        disableAnimation={false}
        renderTopCenterControls={null}
        renderBottomCenterControls={isDefaultBottomRightControlsEnabled ? renderDefaultBottomRightControls : renderBottomCenterControls}
        renderBottomRightControls={isDefaultBottomRightControlsEnabled ? renderDefaultBottomRightControls : renderBottomRightControls}
        renderCenterLeftControls={({
          goToSlide,
          previousSlide,
          previousDisabled,
          onUserNavigation,
          defaultControlsConfig: {
            previousButtonClassName,
            previousButtonStyle = {},
            previousButtonText,
            previousButtonOnClick,
          },
        }) => {
          if (goToSlideRef) {
            goToSlideRef.current = goToSlide;
          }

          const handleClick = (event) => {
            previousButtonOnClick?.(event);

            if (event.defaultPrevented) {
              return;
            }

            onUserNavigation(event);

            event.preventDefault();
            previousSlide();
          };

          if (theme === 'new') {
            return (
              <button
                type='button'
                aria-label='Назад'
                disabled={previousDisabled}
                onClick={handleClick}
                className={classnames(styles.arrowButton, styles.new, { [styles.shadowed]: !!isArrowButtonShadowEnabled })}
              >
                <SvgIcon svg={arrowLeftWithBorder} />
              </button>
            );
          }
          return (
            <button
              type='button'
              aria-label='Назад'
              disabled={previousDisabled}
              onClick={handleClick}
              className={classnames(styles.arrowButton, { [styles.shadowed]: !!isArrowButtonShadowEnabled })}
            >
              <SvgIcon svg={arrowLeft} />
            </button>
          );
        }}
        renderCenterRightControls={({
          goToSlide,
          nextSlide,
          nextDisabled,
          onUserNavigation,
          defaultControlsConfig: {
            nextButtonClassName,
            nextButtonStyle = {},
            nextButtonText,
            nextButtonOnClick,
          },
        }) => {
          if (goToSlideRef) {
            goToSlideRef.current = goToSlide;
          }

          const handleClick = (event) => {
            nextButtonOnClick?.(event);

            if (event.defaultPrevented) {
              return;
            }

            onUserNavigation(event);

            event.preventDefault();
            nextSlide();
          };

          if (theme === 'new') {
            return (
              <button
                type='button'
                aria-label='Вперед'
                disabled={nextDisabled}
                onClick={handleClick}
                className={classnames(styles.arrowButton, styles.new, { [styles.shadowed]: !!isArrowButtonShadowEnabled })}
              >
                <SvgIcon svg={arrowRightWithBorder} />
              </button>
            );
          }
          return (
            <button
              type='button'
              aria-label='Вперед'
              disabled={nextDisabled}
              onClick={handleClick}
              className={classnames(styles.arrowButton, { [styles.shadowed]: !!isArrowButtonShadowEnabled })}
            >
              <SvgIcon svg={arrowRight} />
            </button>
          );
        }}
        {...otherParams}
      >
        {children}
      </Nuka>
    </div>
  );
};

export default Carousel;
