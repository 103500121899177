// eslint-disable-next-line import/no-cycle
import MainFiltersApiResources from '@/core/api/utils/endpoint/main-filters';

import CartApiResource from './cart';
import ContactsApiResource from './contacts';
import ContentApiResource from './content';
import DeliveryApiResource from './delivery';
import FooterApiResource from './footer';
import FormApiResource from './form';
import MenuApiResource from './menu';
import ModelsApiResource from './models';
import ProductsApiResource from './products';
import RedirectsApiResource from './redirects';
import CatalogApiResource from './catalog';
import RegionApiResource from './region';
import ShopsApiResource from './shops';
import UserApiResource from './user';

class ApiResourceOrigin {
  readonly cart: CartApiResource;

  readonly contacts: ContactsApiResource;

  readonly content: ContentApiResource;

  readonly delivery: DeliveryApiResource;

  readonly region: RegionApiResource;

  readonly shops: ShopsApiResource;

  readonly forms: FormApiResource;

  readonly models: ModelsApiResource;

  readonly menu: MenuApiResource;

  readonly products: ProductsApiResource;

  readonly catalog: CatalogApiResource;

  readonly footer: FooterApiResource;

  readonly redirects: RedirectsApiResource;

  readonly user: UserApiResource;

  readonly mainFilters: MainFiltersApiResources;

  constructor() {
    this.cart = new CartApiResource();
    this.contacts = new ContactsApiResource();
    this.content = new ContentApiResource();
    this.delivery = new DeliveryApiResource();
    this.forms = new FormApiResource();
    this.footer = new FooterApiResource();
    this.models = new ModelsApiResource();
    this.menu = new MenuApiResource();
    this.products = new ProductsApiResource();
    this.catalog = new CatalogApiResource();
    this.region = new RegionApiResource();
    this.shops = new ShopsApiResource();
    this.redirects = new RedirectsApiResource();
    this.user = new UserApiResource();
    this.mainFilters = new MainFiltersApiResources();
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
const ApiResource = new ApiResourceOrigin();
export default ApiResource;
