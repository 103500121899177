/* eslint-disable react/forbid-prop-types */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import scrollbarSize from 'dom-helpers/scrollbarSize';
import throttle from 'lodash/throttle';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import PropTypes from 'prop-types';

import useResizeObserver from '@/shared/hooks/useResizeObserver';

import { PagePropsContext } from '../../contexts';
import getDefaultWidth from '../../helpers/getDefaultWidth';
import useWindowResize from '../../hooks/useWindowResize';
import closeIcon from '../../icons/CloseIcon';
import searchIcon from '../../icons/SearchIcon';
import useLayoutMeasures from '../../src/application/config/useLayoutMeasures';
import { useAppCart } from '../../src/core';
import Logo from '../Logo';

import HeaderContent from './components/HeaderContent';
import HeaderHints from './components/HeaderHints';
import HeaderNav from './components/HeaderNav';

import styles from './Header.module.css';

const DynamicDrawer = dynamic(() => import('./components/Drawer'), { ssr: false });
const DynamicTabBar = dynamic(() => import('./components/TabBar'), { ssr: true });

const Header = (props) => {
  const {
    isAmp,
    mode,
    info,
    favorites,
    contactsData,
    isPhone,
    isTablet,
    isRegionConfirmationShowed,
    allArticles,
    onGetAllArticles,
  } = props;

  const { user } = useContext(PagePropsContext);
  const cart = useAppCart();

  const cartCount = cart?.items?.reduce((acc, item) => acc + item.quantity, 0);

  const searchInputRef = useRef();

  const defaultWidth = getDefaultWidth({ isPhone, isTablet });

  const [scrollY, setScrollY] = useState(0);

  const throttledSetScrollY = useCallback(
    throttle((x) => {
      setScrollY(x);
    }, 50),
    []
  );

  const { headerRefEntry, onHeaderResize: onResize } = useLayoutMeasures();
  const [setHeaderRef, ref] = headerRefEntry;
  useResizeObserver({ target: ref, callback: onResize });

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.pageYOffset || window.scrollY;

      throttledSetScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const windowSize = useWindowResize();

  const width = windowSize.width || defaultWidth;

  const [scrollbarSizeValue, setScrollbarSizeValue] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [stack, setStack] = useState(['main']);

  useEffect(() => {
    setScrollbarSizeValue(scrollbarSize());
  }, []);

  const toggleFreezeBody = (value) => {
    const { body } = document;

    if (value) {
      const windowScrollY = scrollY;

      body.style.position = 'fixed';
      body.style.top = `-${windowScrollY}px`;
      body.style.paddingRight = `${scrollbarSizeValue}px`;
    } else {
      const bodyScrollY = body.style.top;

      body.style.position = '';
      body.style.top = '';
      body.style.paddingRight = '';

      window.scrollTo(0, parseInt(bodyScrollY || '0', 10) * -1);
    }
  };

  const handleCloseDropdown = () => {
    // setIsDrawerOpen(false);
    // toggleFreezeBody(false);
  };

  const handleToggleDrawer = () => {
    const value = !isDrawerOpen;

    setIsDrawerOpen(value);

    toggleFreezeBody(value);
  };

  const handleToggleCatalogDrawer = ({ isOpen }) => {
    setIsDrawerOpen(!!isOpen);

    toggleFreezeBody(!!isOpen);
  };

  const handleToggleDropdown = ({ isOpen }) => {
    toggleFreezeBody(isOpen);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);

    toggleFreezeBody(false);
  };

  const handleTabBarClick = () => {
    handleDrawerClose();
  };

  const handleClearSearchClick = () => {
    if (searchInputRef?.current?.value) {
      searchInputRef.current.value = '';

      searchInputRef.current.focus();
    }
  };

  const headerStyle = {
    paddingRight: isDrawerOpen ? scrollbarSizeValue : 0,
  };

  const isMiniMode = mode === 'mini';

  if (isAmp) {
    return (
      <>
        <header
          className={classnames('header', styles.header)}
          style={headerStyle}
          itemScope
          itemType='https://schema.org/WPHeader'
          ref={setHeaderRef}
        >
          <Link href='/' itemProp='url' prefetch={false}>
            <Logo />
          </Link>

          <div role='button' on='tap:amp-sidebar.toggle' tabIndex='0' className='hamburger'>
            ☰
          </div>
        </header>

        <DynamicDrawer isAmp={isAmp} info={info} />
      </>
    );
  }

  return (
    <>
      <header
        className={classnames('header', styles.header)}
        style={headerStyle}
        itemScope
        itemType='https://schema.org/WPHeader'
        ref={setHeaderRef}
      >
        {!isMiniMode && <HeaderHints info={info} />}

        {!isMiniMode && (
          <HeaderNav
            allArticles={allArticles}
            onGetAllArticles={onGetAllArticles}
            isRegionConfirmationShowed={isRegionConfirmationShowed}
            info={info}
            favorites={favorites}
            cartCount={cartCount}
            isDrawerOpen={isDrawerOpen}
            onCloseDropdown={handleCloseDropdown}
            onToggleDropdown={handleToggleDropdown}
            onToggleDrawer={handleToggleDrawer}
            user={user}
          />
        )}

        <HeaderContent
          allArticles={allArticles}
          onGetAllArticles={onGetAllArticles}
          info={info}
          contactsData={contactsData}
          onToggleDropdown={handleToggleDropdown}
          onCloseDropdown={handleCloseDropdown}
        />

        {!isMiniMode && isDrawerOpen && (
          <DynamicDrawer
            allArticles={allArticles}
            onGetAllArticles={onGetAllArticles}
            info={info}
            contactsDat={contactsData}
            isOpen={isDrawerOpen}
            onCloseClick={handleDrawerClose}
            stack={stack}
            onSetStack={setStack}
          />
        )}

        <div className={styles.search}>
          <form className={styles.searchContent}>
            <label htmlFor='search-mobile' className={styles.searchIcon}>
              {searchIcon}
            </label>
            <input
              id='search-mobile'
              ref={searchInputRef}
              className={classnames(styles.searchInput, 'search-input', 'search-input__mobile')}
              placeholder='Поиск'
            />
            <button type='button' className={styles.searchClose} onClick={handleClearSearchClick}>
              {closeIcon}
            </button>
          </form>
        </div>
      </header>

      {(isTablet || isPhone || width < 1024) && (
        <DynamicTabBar
          stack={stack}
          onSetStack={setStack}
          favoritesCount={favorites?.length}
          cartCount={cartCount}
          onMenuClick={handleTabBarClick}
          onMenuCatalogClick={handleToggleCatalogDrawer}
          isMenuOpen={isDrawerOpen}
          user={user}
        />
      )}
    </>
  );
};

Header.propTypes = {
  isAmp: PropTypes.bool,
  mode: PropTypes.string,
  articles: PropTypes.array,
};

Header.defaultProps = {
  isAmp: false,
  mode: null,
  articles: null,
};

export default Header;
